.login-topbar{
  width: 100%;
  /* top: 0; */
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border: rgba(203, 199, 199, 1); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  /* z-index: 9999; */
} 

.login-topbarWrappers{
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.login-topleft{
  display: flex;
  flex-direction: row;
  padding-left: 5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

/* .login-pickloadlogo{
  padding-top: 5vh;
  padding-bottom: 5vh;
} */



@media screen and (max-width: 450px){
  .project-title{
      display: none;
  }
  .AdminField{
      display: none;
  }
  .logo{
      width: auto;
      height: auto;
  }
  .topbarWrappers{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
}