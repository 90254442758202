.successfully-cancelled{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.cancelled-background{
    width: auto;
    height: 65vh;
}

.successfully-cancelled-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.cancelled-confirmation{
    width: 35vw;
    height: 50vh;
    margin-top: 20vh;
    background-color: white;
    /* border: 1px solid #1faa08; */
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color:white;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
}

.cancelled-confirmation-info{
    width: 35vw;
    height: 40vh;
    display: block;
    background-color: #1faa08;
    border: 1px solid #1faa08;
    color:white;
    margin-bottom: 20px;
    text-align: center;
    font-size: 12px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rematched{
    margin-bottom: 20px;
    margin-top: 25px;
    justify-content: center;
}


.view-details{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.details{
    background-color: #1fa008;
    border-radius: 6px;
    border: 1px solid #1fa008;
    color: white;
    padding: 5px 40px;
    cursor: pointer;
    font-size: medium;
    text-align: center;
}

@media screen and (max-width: 900px){
    .successfully-cancelled{
        margin-top: auto;
    }
}
