.agent-form {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
}

.back3 {
  padding-top: 20px;
  margin: 30px 20px;
}

.agent-form-props {
  margin-left: 5vw;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 80vh;
}

.agent-form-title {
  font-size: 26px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 3vh;
}

.form-details {
  font-size: 17px;
  margin-bottom: 30px;
}

.agent-form-label {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 300;
  margin-bottom: 1px;
}

#vehicle-images-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.vh-update-button {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border: 1px solid #1aa803;
  width: 10vw;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  height: auto;
}
.add-more-images-button {
  display: inline;
  background-color: #1aa803;
  color: white;
  margin-left: 20px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  height: 30px;
  width: 30px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* justify-content: center; */
  /* height: auto; */
}

.add-agent-form {
  background-color: rgba(217, 217, 217, 0.5);
  border: none;
  border-radius: 5px;
  padding-top: 7px;
  padding-right: 20vw;
  padding-left: 5px;
  padding-bottom: 7px;
  outline: none;
  margin-bottom: 10px;
}

.form-information {
  background-color: rgba(217, 217, 217, 0.5);
  border: none;
  border-radius: 5px;
  padding-top: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 300;
  width: 30vw;
  /* height: 5vh; */
  padding-left: 10px;
  padding-bottom: 10px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 10px;
}

.form-information1 {
  background-color: rgba(217, 217, 217, 0.5);
  border: none;
  border-radius: 5px;
  padding-top: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 300;
  width: 30vw;
  padding-left: 10px;
  padding-bottom: 8px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 10px;
}

.passport {
  margin-top: 2vh;
  width: 7vw;
  height: 17vh;
}

.state-gender {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
}

.state-row {
  display: flex;
  flex-direction: column;
  margin-right: 5vw;
}

.state-information {
  background-color: rgba(217, 217, 217, 0.5);
  border: none;
  border-radius: 5px;
  padding-top: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 12.5vw;
  padding-left: 5px;
  padding-bottom: 5px;
  outline: none;
  margin-bottom: 10px;
  margin-top: 5px;
}

.gender-information {
  background-color: rgba(217, 217, 217, 0.5);
  border: none;
  border-radius: 5px;
  padding-top: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  width: 12.5vw;
  padding-left: 5px;
  padding-bottom: 5px;
  outline: none;
  margin-bottom: 10px;
  margin-top: 5px;
  height: 27px;
}

.licence-information {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 10px;
}

.vehicle-information {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.vehicle-information-unit {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  margin-bottom: 20px;
  /* padding-bottom: 50px; */
}

.vh-buttons-container{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  gap: 10px;
  /* background-color: red;
  width: 100%; */
}

.agent-line {
  margin-left: -2vw;
  width: 70vw;
  margin-top: 4vh;
  margin-bottom: 4vh;
  color: rgba(0, 0, 0, 0.65);
  /* background-color: rgba(0, 0, 0, 0.65); */
}

.vehicle-image {
  width: 15vw;
  height: 20vh;
  border-radius: 5px;
  margin-right: 2vw;
  object-fit: cover;
  border: 1px rgb(189, 188, 188) solid
}

.licence-image {
  width: 14vw;
  height: 20vh;
  border-radius: 5px;
  margin-right: 2vw;
  object-fit: cover;
}

.accept-form {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 5vh;
}

.accept {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border: 1px solid #1aa803;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8vh;
  margin-left: 4vw;
}

.decline {
  background-color: tomato;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border: 1px solid tomato;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8vh;
  margin-right: 4vw;
}

@media only screen and (max-width: 900px) {
  .agent-form {
    width: 95%;
    margin-top: auto;
    margin-left: 3.5vw;
  }

  .licence-information {
    display: flex;
    flex-direction: column;
  }

  .vehicle-information {
    display: flex;
    flex-direction: column;
  }

  .vehicle-image {
    width: 40vw;
    margin-bottom: 5px;
  }

  .licence-image {
    width: 40vw;
    margin-bottom: 5px;
  }

  .passport {
    width: 30vw;
    margin-bottom: 5px;
  }

  .form-information {
    width: 60vw;
  }
}
