.logout{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.logout1{
    /* width: 81.7%; */
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: 6vh;
    background-color: white;
    /* overflow-y: scroll; */
    height: 86vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-confirmation1{
    width: 35vw;
    height: 50vh;
    margin-top: 8.5vh;
    background-color: white;
    /* border: 1px solid #1faa08; */
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color:white;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
    font-weight: 300;
}

.logout-confirmation1 p {
    color: white;
}

.logout-background{
    width: auto;
    height: 65vh;
}

.logout-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logout-confirmation{
    width: 35vw;
    height: 50vh;
    margin-top: 20vh;
    background-color: white;
    /* border: 1px solid #1faa08; */
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color:white;
    box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
    font-weight: 300;
}

.logout-confirmation-info{
    width: 35vw;
    height: 40vh;
    /* display: block; */
    background-color: #1faa08;
    border: 1px solid #1faa08;
    color:white;
    margin-bottom: 20px;
    text-align: center;
    font-size: 19px;
    font-weight: 300;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.logout-rematched{
    margin-bottom: 20px;
    margin-top: 10px;
}

.logout-dialog-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.logout-dialog{
    color: #1fa008;
    border-radius: 6px;
    border: 1px solid #1fa008;
    background-color: white;
    padding: 5px 60px;
    cursor: pointer;
    font-size: medium;
}


.logout-dialog1{
    background-color: #1fa008;
    border-radius: 6px;
    border: 1px solid #1fa008;
    color: white;
    padding: 5px 60px;
    cursor: pointer;
    font-size: medium;
}






@media screen and (max-width: 900px){
    .logout{
        margin-top: auto;
    }
}