.individual-fleet-screen{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.individual-fleet-details{
    padding-left: 10px;
    padding-bottom: 20px;
}



.fleet-details{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.fleet-profile{
    border-radius: 50%;
    width: 11vw;
    height: 21vh;
}

.fleet-image{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.fleet-manager-name{
    font-weight: 700;
    margin-bottom: -2.5vh;    
    font-size: 17px; 
}

.fleet-about{
    line-height: 3.5vh;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}

.fleet-about1{
    line-height: 3vh;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}

.fleet-vehicle{
    line-height: 3.5vh;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}

span {
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px; 
    font-size: 17px;  
    color: rgba(0, 0, 0, 0.8);
}




.fleet-table{
    justify-content: space-evenly;
    margin-top: 4vh;
    margin-left: 6vw;
}


.no-delivery-fleet-manager{
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    font-size: 19px;
    margin-left: 50px;
    margin-top: 20px;
}

@media screen and (max-width: 900px){
    .individual-fleet-screen{
        margin-top: auto;
    }
}
