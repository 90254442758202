.delivery-agents{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.agent-properties{
    margin-left: 20px;
    margin-top: 20px;
}
.agent-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 29px;
}

.agent-search-box-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    /* margin-left: 30px; */
}


.agent-table{
    margin-left: 1.4vw;
    width: 87%;
    line-height: 4.5vh;
    border-collapse: collapse;
    font-size: 13px;
    margin: 0 auto;
}

.agent-table-left{
    margin-left: 1vw;
}



.agent-container{
    margin-left: -29vw;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    margin-bottom: 2vh;
}

.delivery-agent-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
    margin-right: 12vw;
}

.drop-down3 {
    list-style: none;
    transition: .5s;
    margin-top: -16.5vh;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
    margin-left: 0.6vw;
}

.drop-down3 li {
    border-bottom: 1px solid #cccccc;
    text-align: center;
    background: #fbf6f6;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 5px;
    height: 39px;
    font-size: 14px;
    margin-left: -2vw;
}
.drop-down3 li:hover {
    color: #1aa803;
}

.agent-App{
    position: absolute;
    width: 11rem;
    font-size: 10px;
    align-items: center;
    display: inline;
    margin-top: -1.5vh;
    margin-left: -5.3vw;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
}

.popup-click{
    position: relative;
    background-color: transparent;
}


.loading-pages{
    justify-content: center;
    text-align: center;
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    padding-top: 49vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
}



@media only screen and (max-width: 900px){
    .delivery-agents{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    }

    .delivery-agent-row{
        font-size: 10px;
    }    
}

