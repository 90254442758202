.individual-delivery-agent-screen{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.delivery-agent-details{
    padding-left: 10px;
    padding-bottom: 20px;
}


.agent-details{
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-bottom: 20px;
}


.user-profile{
    border-radius: 50%;
    width: 11vw;
    height: 21vh;
}

.user-image{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}


.agent-about{
    line-height: 3.5vh;
    color: rgba(0, 0, 0, 0.6);

}

.agent-flboard{
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    /* margin-top: 5px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -2vh;
}

.agent-stats-board{
    border: 1.5px solid #DADADA;
    padding: 15px 15px 30px 10px;
}

span{
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px;
    font-size: 11px;  
    color: rgba(0, 0, 0, 0.8);
}

.flash-display{
    font-size: 13px;
}

.earning-board{
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
}

.agent-history{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 20px;    
}

.agent-instant-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
    height: 80px;
}npm

.agent-ratings{
    margin-left: 3vw;
}

.agent-about-name{
    font-weight: 700;
    margin-bottom: -2.5vh;    
    font-size: 16px; 
}
.agent-name-info{
    font-weight: 500;
    margin-bottom: -2.5vh;    
    font-size: 30px; 
    color: black;
}


.agent-scheduled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
}

.agent-cancelled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
}

.deliv-icons{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.agent-flash-display{    
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    font-weight: 600;
}

.agent-flash-display-no{
    font-size: 14px;
    margin-top: -11px;
}

.agent-ratings-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.user-image-review{
    border-radius: 80px;
    margin-left: 10px;
    margin-top: 10px;
    width: 50px;
    height: 50px;
}

.user-review{
    border: 1.5px solid #DADADA;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 40vw;
    height: auto;
}

.review-field{
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 20px;
}

.review-name{
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
}

.review-text{
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}

.delivery-vehicle-type-medium2{
    margin-top: 25px;
}

.delivery-vehicle-type-medium-name1{
    margin-bottom: 5px;
    color: #0A1034;
    font-size: 16px;
    font-weight: 600; 
}

.delivery-vehicle-type-medium-name-vehicle1{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.delivery-vehicle-type-medium-name-vehicle2{
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 120px;
    border-radius: 6px;
    margin-right: 30px;
}




@media only screen and (max-width: 900px){
    .individual-delivery-agent-screen{
        width: 95%;
        margin-top: auto;
        margin-left: 2.4vw;
    }
    
    .agent-details{
        display: flex;
        flex-direction: row;
        align-content: baseline;
        justify-content: space-between;
    }
    
    .agent-image{
        width: 20vw;
        height: 12vh;
        border-radius: 50%;
    }

    .agent-about{
        font-size: 10px;
        margin-left: 20px;
    }

    span{
        font-size: 10px;
    }

    .earning-board{
        width: 5vw;
        height: 11vh;
        font-size: 11px;
        margin-right: 5px;
    }

    .agent-flboard{
        font-size: 11px;
    }

    .agent-history{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .deliv-icons{
        width: 3vw;
        height: 3vh;
    }

    .agent-instant-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .agent-scheduled-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .agent-cancelled-request{        
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
        margin-right: 6px;
    }

    /* span{
        font-size: 9px;
    } */

    .agent-reviews{
        display: flex;
        flex-direction: column;
    }

    .user-review{
        width: 70vw;
    }

    .review-text{
        font-size: 10px;
    }
    
}


.individual-delivery-agent-screen{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.delivery-agent-details{
    padding-left: 10px;
    padding-bottom: 20px;
}


.agent-details{
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-bottom: 20px;
}


.user-profile{
    border-radius: 50%;
    width: 11vw;
    height: 21vh;
}

.user-image{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}


.agent-about{
    line-height: 3.5vh;
    color: rgba(0, 0, 0, 0.6);

}

.agent-flboard{
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    /* margin-top: 5px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -2vh;
}

.agent-stats-board{
    border: 1.5px solid #DADADA;
    padding: 15px 15px 30px 10px;
}

span{
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px;
    font-size: 11px;  
    color: rgba(0, 0, 0, 0.8);
}

.flash-display{
    font-size: 13px;
}

.earning-board{
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
}

.agent-history{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 20px;    
}

.agent-instant-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
    height: 80px;
}

.agent-ratings{
    margin-left: 3vw;
}

.agent-about-name{
    font-weight: 700;
    margin-bottom: -2.5vh;    
    font-size: 16px; 
}


.agent-scheduled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
}

.agent-cancelled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 10px 13px 7px 6px;
}

.deliv-icons{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.agent-flash-display{    
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    font-weight: 600;
}

.agent-flash-display-no{
    font-size: 14px;
    margin-top: -11px;
}

.agent-ratings-title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.user-image-review{
    border-radius: 80px;
    margin-left: 10px;
    margin-top: 10px;
    width: 50px;
    height: 50px;
}

.user-review{
    border: 1.5px solid #DADADA;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 40vw;
    height: auto;
}

.review-field{
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-left: 20px;
}

.review-name{
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
}

.review-text{
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}

.delivery-vehicle-type-medium2{
    margin-top: 25px;
}

.delivery-vehicle-type-medium-name1{
    margin-bottom: 5px;
    color: #0A1034;
    font-size: 16px;
    font-weight: 600; 
}

.delivery-vehicle-type-medium-name-vehicle1{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.delivery-vehicle-type-medium-name-vehicle2{
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 120px;
    border-radius: 6px;
    margin-right: 30px;
}




@media only screen and (max-width: 900px){
    .individual-delivery-agent-screen{
        width: 95%;
        margin-top: auto;
        margin-left: 2.4vw;
    }
    
    .agent-details{
        display: flex;
        flex-direction: row;
        align-content: baseline;
        justify-content: space-between;
    }
    
    .agent-image{
        width: 20vw;
        height: 12vh;
        border-radius: 50%;
    }

    .agent-about{
        font-size: 10px;
        margin-left: 20px;
    }

    span{
        font-size: 10px;
    }

    .earning-board{
        width: 5vw;
        height: 11vh;
        font-size: 11px;
        margin-right: 5px;
    }

    .agent-flboard{
        font-size: 11px;
    }

    .agent-history{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .deliv-icons{
        width: 3vw;
        height: 3vh;
    }

    .agent-instant-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .agent-scheduled-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .agent-cancelled-request{        
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
        margin-right: 6px;
    }

    /* span{
        font-size: 9px;
    } */

    .agent-reviews{
        display: flex;
        flex-direction: column;
    }

    .user-review{
        width: 70vw;
    }

    .review-text{
        font-size: 10px;
    }
    
}

