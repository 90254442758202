.individual-agent-payment{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
    overflow-x: hidden;
}


.individual-agent-details{
    padding-left: 10px;
    padding-bottom: 20px;
}



.week-no{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    padding-left: 10px;
}

.not-paid-logo{
    background-color: #C4C4C4B2; 
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 50px 10px;
    border-radius: 4px;
}

.paid-logo{
    background-color: #F5EC16; 
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 50px 10px;
    border-radius: 4px;
}

.agent-pay-details{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    align-content: baseline;
}

.paymentsss-br{
    margin-bottom: 11px;
}

.agent-pay-image{
    margin-top: -20px;
    border-radius: 50%;
    width: 150px;
    height: 160px;
}

.agent-pay-about{
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
    font-weight: 300;
}

span{
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px;
    font-size: 16px;  
    color: rgba(0, 0, 0, 0.8);
}

.individual-agent-row{
    margin-top: 20px;
    width: 80vw;
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
}

.individual-agent-view-pages{
    word-spacing: 3px;
    display: flex;
    flex-direction: row;
}

.more{
    margin-left: 20px;
    margin-right: 20px;
}

.agent-payment{
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}


 

@media only screen and (max-width: 900px){
    .individual-agent-payment{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    }

    .week-no{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

    .not-paid-logo{
        font-size: 11px;
        padding: auto;
    }

    .paid-logo{
        font-size: 11px;
        padding: auto;
    }
    
    .agent-pay-about{
        font-size: 10px;
        line-height: auto;
    }

    span{
        font-size: 11px;
    }

    .agent-pay-image{
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }
}
