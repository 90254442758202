.revenue{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    overflow-y: scroll;
    height: 86vh;
}

.revenue-grid{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.revenue-grid-1{
    display: flex;
    flex-direction: column;
}

.revenue-grid-2{
    display: flex;
    flex-direction: column;
}

.revenue-item1{
    width: 27vw;
    height: 20vh;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
    padding-bottom: 5%;
}
.revenue-item3{
    width: 27vw;    
    /* height: 15vh; */
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
}

.revenue-item2{
    width: 27vw;    
    /* height: 15vh; */
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
}

.revenue-item4{
    width: 56vh;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
    padding-bottom: 25%;
}

.revenue-item5{
    width: 56vh;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
    padding-bottom: 25%;
}

.revenue-design1{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #C4C4C4;
    border-bottom-left-radius: 100%;
}

.revenue-design2{
    width: 60px;
    height: 50px;
    float: right;;
    background-color: #5BC0EB;
    border-top-left-radius: 100%;
}

.revenue-design3{
    width: 60px;
    height: 50px;
    float: right;
    background-color: rgba(255, 10, 10, 0.8);
    border-top-left-radius: 100%;
}

.revenue-design4{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #1AA803;
    border-bottom-left-radius: 100%;
}

.revenue-design5{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #F5EC16;
    border-bottom-left-radius: 100%;
}

.revenue-iterations{
    padding-left: 20px;
    font-size: 30px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6); 
    margin-top: 5px;
    /* margin-bottom: -5vh; */
    font-weight: 300;
}


.no-Iterations{
    font-size: 30px;
    font-weight: 300;
    margin-top: 10vh;
}


.card{
    margin-left: 1.6vw;
    width: 96%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.calender-revenue{
    text-align: center;
    margin-bottom: 20px;
}

.calender-revenue{
    background-color: white;
    color: black;
    border: 1px solid rgba(76, 76, 76, 1);
    border-radius: 4px;
    font-size: 14px;
    /* margin-top: 20px; */
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 10px 20px 10px;
}


.calender-revenue1{
    background-color: white;
    color: black;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 10px 20px 10px;
}


.revenue-barchart{
    margin-top: 5vh;
    width: 55vw;
    margin-bottom: 3vh;

}

.revenue-doughnut-chart{
    width: auto;
}



@media only screen and (max-width: 900px){
    .revenue{
        width: 95%;
        margin-top: auto;
        margin-left: 3vw;
    }
    .revenue-grid{
        display: flex;
        flex-direction: column;        
    }

    .revenue-item1{
        margin-bottom: 6px;
    }
    .revenue-item2{
        margin-bottom: 6px;
    }
    .revenue-item3{
        margin-bottom: 6px;
    }
    .revenue-item4{
        margin-bottom: 6px;
    }
    .revenue-item5{
        margin-bottom: 6px;
    }

        
    .revenue-item1{
        width: auto;
        background-color: white;
        text-align: left;
        padding-left: 8px;
        line-height: 30px;
        text-decoration: none;
        margin-bottom: 5%;
        padding-bottom: 10%;
    }
    .revenue-item3{
        width: auto;
        background-color: white;
        text-align: left;
        padding-left: 8px;
        line-height: 30px;
        text-decoration: none;
        margin-bottom: 5%;
        padding-bottom: 10%;
    }

    .revenue-item2{
        width: auto;
        background-color: white;
        text-align: left;
        padding-left: 8px;
        line-height: 30px;
        text-decoration: none;
        margin-bottom: 5%;
        padding-bottom: 10%;
    }

    .revenue-item4{
        width: auto;
        background-color: white;
        text-align: left;
        padding-left: 8px;
        line-height: 30px;
        text-decoration: none;
        margin-bottom: 5%;
        padding-bottom: 10%;
    }

    .revenue-item5{
        width: auto;
        background-color: white;
        text-align: left;
        padding-left: 8px;
        line-height: 30px;
        text-decoration: none;
        margin-bottom: 5%;
        padding-bottom: 10%;
    }
}
