.topbar{
    width: 100vw;
    /* top: 0; */
    margin: 0;
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: rgba(203, 199, 199, 1);
    border-bottom: 1px solid rgba(26, 168, 3, 0.15);
    position: sticky;
    top: 0;
    z-index: 9999;
} 

.topbarWrappers{
    height: auto;
    padding-top: 6px;
    /* padding-left: 60px;
    padding-right: 60px; */
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.topleft{
    display: flex;
    flex-direction: row;
    padding-left: 5vw;
}

.topRight{
    display: flex;
    flex-direction: row;
    padding-right: 6vw;
    cursor: pointer;
}

.AdminField{
    /* padding-top: 5px; */
    padding-left: 10px;
}

.AdminName{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
}
.AdminRank{
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
}


.logo{
    padding-top: 3px;
}

.topbar-image{
    border-radius: 50%;
}

@media screen and (max-width: 450px){
    .project-title{
        display: none;
    }
    .AdminField{
        display: none;
    }
    .logo{
        width: auto;
        height: auto;
    }
    .topbarWrappers{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}