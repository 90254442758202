.system-message{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.system-message-title{
    margin-bottom: 40px;
    margin-left: 10px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    font-size: 20px;
}

.system-message-properties{
    margin-left: 4vw;
    margin-right: 4vw; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.send-message-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.message-master{
    border: 1.5px solid #DADADA;
    padding: 15px;
    width: 20vw;
}

.system-message-icon{
    width: 40px;
    height: 40px;
    margin-right: 6px;
}

.system-text-up{
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.72);
}


.all-user-agent-message-props{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: top;
}

.message-btn{
    margin-top: -52px;
    margin-left: 14px;
    margin-right: -5px;
    width: 24px;
    height: 24px;
    border: 1px solid green;
}

.all-user-agent-no{
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-weight: 400;
    padding-right: 14px;
}








.message-master1{
    border: 1.5px solid #DADADA;
    padding-top: 15px;
    padding-bottom: 15px;
    /* padding-left: 20px; */
    width: 20vw;
}

.system-message-icon1{
    width: 40px;
    height: 40px;
    margin-right: 4px;
}

.system-text-up1{
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    margin-right: 4px;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.72);
}


.all-user-agent-message-props1{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-around;
}

.message-btn1{
    width: 24px;    
    margin-left: 5vw;
    margin-top: -52px;
    height: 24px;
    margin-right: -5px;
}

.all-user-agent-no1{
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-weight: 400;
    padding-right: 10px;
}





.message-master2{
    border: 1.5px solid #DADADA;
    padding: 15px;
    width: 20vw;
}

.system-message-icon2{
    width: 40px;
    height: 40px;
    margin-right: 6px;
}

.system-text-up2{
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.72);
}


.all-user-agent-message-props2{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: top;
}

.message-btn2{
    margin-top: -52px;
    margin-left: 5vw;
    width: 24px;
    height: 24px;
    border: 1px solid green;
}

.all-user-agent-no2{
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-weight: 400;
    padding-right: 3px;
}









.all-message-text{
    /* resize: none; */
    width: 66.5vw;
    background-color: rgba(217, 217, 217, 0.3);
}

.all-message-area{
    resize: none;
    margin-top: 5vh;
    /* padding-right: 69vw; */
    margin-left: 3vw;
}

textarea{
    resize: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    padding: 20px;
    padding-bottom: 30vh;
    border-radius: rgba(0, 0, 0, 0.2);
    outline: none;
}

.send-all-message{    
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-top: 3vh;
    margin-right: 2.2vw;
    margin-bottom: 5vh;
    cursor: pointer;
    width: 10vw;
    float: right;
    font-weight: 400px;
}








@media screen and (max-width: 900px){
    .system-message{
        margin-top: -84vh;
    }
}
