.reports{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}


.toggle-div {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
  .report-toggle,
  .report2-toggle {
    margin-top: 4vh;
    width: 23vw;
    padding: 10px 20px;
    border: 1px solid #1aa803;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
    transition: 0.4s;
}
/* .green-img {
    display: none;
} */
.report4-toggle,
  .report5-toggle {
    margin-top: 4vh;
    width: 18vw;
    font-size: 15px;
    padding: 10px 20px;
    border: 1px solid #1aa803;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
    transition: 0.4s;
    margin-bottom: 20px;
}


#active {
    background-color: #1aa803;
    color: white;
    margin-right: 0px;
}
#inactive {
    margin-left: -20px;
    padding-left: 40px;
    color: #1aa803;
}
  #active2 {
    background-color: #1aa803;
    color: white;
    margin-left: 0px;
  }
#inactive2 {
    margin-right: -20px;
    padding-right: 40px;
    color: #1aa803;
}
  

.reports-properties{
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.report-information{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.reports-switch{
    font-size: 19px;
    /* font-weight: 600; */
    display: flex;
    justify-content: center;
}

.calender-btn{
    text-align: center;
    margin-bottom: 20px;
}

.calender-report{
    background-color: white;
    color: black;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px 20px 10px;
}

.report-details{
    padding: 10px 45px 15px 7px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: rgba(217, 217, 217, 0.5);
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
    width: 70vw;

}

.switch-btn{
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 19px;
    font-weight: 600;
}

.switch-btn1{
    background-color: white;
    border: 1px solid #1aa803;
    color: #1aa803;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
}

.report-pic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.report-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.individual-report{
    display: flex;
    flex-direction: row;
    padding-left: 1vw;
    color: rgba(0, 0, 0, 0.9);
}

.report-time{
    display: flex;
    justify-content: flex-end;  
    color: rgba(0, 0, 0, 0.9);
    font-size: 13px;
    font-weight: 400;
}

.report-key{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
}


.report-name{
    margin-left: 17px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
}

.report-summary{
    margin-left: 17px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
}

.read {
    display: flex !important;
}

.report-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 15px;
}

.report-click-btn{
    background-color: white;
    border: 1px solid white;
    color: rgba(0, 0, 0, 0.9);
    width: 11vw;
    height: fit-content;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 300;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);

}



@media only screen and (max-width: 900px){
    .reports{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    } 

    .switch-btn{
        font-size: 10px;
    }
    
    .switch-btn1{
        font-size: 10px;
    }

    .calender-report{
        font-size: 10px;
        padding: auto;
    }

    .report-btns{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .report-click-btn{
        font-size: 7px;
        width: auto;
        margin-left: 6px;
    }

    .report-time{
        font-size: 10px;
    }

    .individual-report{
        justify-content: baseline;
    }

    .report-name{
        font-size: 14px;
    }

    .report-summary{
        font-size: 11px;
        margin-top: 13px;
    }
}

.parent-container-spec{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 50px;
}

.main-container-spec{
    display: flex;
    flex-direction: column;
    background-color: #1aa803;
    color: white;
    font-size: 18px;
    height: 350px;
    width: 490px;
    align-items: center;
    padding: 10px;
}

.main-container-spec p {
    color: white;
}