.deliverys-details{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    height: 86vh;
    overflow-y: scroll;
    background-color:  #FEFEFE;
}

.delivery-details-props{
    margin-left: 30px;
    margin-top: 20px;
}
.instant-delivery-summary-title{
    font-size: 24px;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
    color: #0A1034;
}

.instant-delivery-title{
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin-bottom: 60px;
    color: #0A1034;
}

.delivery-acception{
    margin-top: 20px;
    margin-bottom: 40px;
    color: #0A1034;
    font-size: 17px;
    font-weight: 500;
}

.instant-delivery-details{
    display: flex;
    flex-direction: row;
    font-size: 15px;
}

.instant-delivery-agent-image{
    height: 10%;
    width: auto;
    border-radius: 50%;
}

.deliveryimage{
    height: 170px;
    width: 170px;
    border-radius: 50%;
}

.instant-delivery-agent-details{
    font-size: 15px;
    font-weight: 600;
    color: #0A1034;
    margin-left: 2%;
    line-height: 150%;
    margin-bottom: 5%;
    pointer-events: none;
}

.delivery-vehicle-type-medium{
    margin-left: 80px;
}

.delivery-vehicle-type-medium-name{
    margin-bottom: 5px;
    color: #0A1034;
    font-size: 16px;
    font-weight: 500; 
    margin-left: 90px; 
}

.delivery-vehicle-type-medium-name-vehicle{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    width: 120px;
    height: 140px;
    border-radius: 6px;
}

#numbers-details{
    font-weight: 400;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 1vw;
    border-style: none;
}

.delivery-line-breaker{
    width: 94%;
    margin-bottom: 3%;
}


.instant-delivery-details1{
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    pointer-events: none;
}



.instant-delivery-client-details1{
    font-size: 15px;
    font-weight: 600;
    color: #0A1034;
    line-height: 150%;
    margin-bottom: 4%;
    justify-content: space-evenly;
    pointer-events: none;
}

#numbers-details1{
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 1vw;
    border-style: none;
}

.delivery-images-title{
    font-size: 15px;
    font-weight: 600;
    color: #0A1034;
    margin-bottom: 4vh;
}

.delivery-images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 520px;
    height: 10%;
}

.delivery-images-details{
    width: 150px;
    height: 150px;
    border-radius: 6px;
}

.delivery-line-breaker1{
    width: 94%;
    margin-bottom: 5%;
    margin-top: 3%;
}


.delivery-deatails-location-pickup{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.location-img {
    width: 27px;
    height: 100px;
    margin-right: 1%;
}

.location-img img {
    width: 100%;
    height: 100%;
}

.delivery-location{
    font-size: 12px;
    margin-top: 46px;
}

.pickup-location{
    font-size: 12px;
}


.cancelling{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.72);
    margin-top: 5px;
    font-weight: 600;
    margin-bottom: 10px;
}

#cancel-purpose{
    font-size: 18px;
    color: rgba(0, 0, 0, 0.72);
    font-weight: 400;
    word-spacing: normal;
    margin-bottom: 20px;
}

.pairing{    
    align-items: center;
    text-align: center;
    justify-content: center;
}

.pair{
    background-color: #1aa803;
    color: white;
    border: 1px solid #1aa803;
    font-size: 16px;
    padding: 10px;
    /* font-weight: 300; */
    border-radius: 6px;
    margin-bottom: 40px;
    cursor: pointer;
}

.button-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.chizzy-delivery-button {
    background-color: #1aa803;
    color: white;
    border: 1px solid #1aa803;
    border-radius: 6px;
    font-size: 16px;
    width: 170px;
    height: 40px;
    padding:3px;
    margin-top: 20px;   
}

.chizzy-marg{
    margin-bottom: 34px;
}
/* knight */
.refund-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.refund-button {
    background-color: red;
    color: white;
    border: 1px solid red;
    border-radius: 6px;
    font-size: 16px;
    width: 150px;
    height: 40px;
    padding:3px;
    margin-top: 20px;   
}
