.individual-user-screen{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.individual-user-details{
    padding-left: 14px;
    padding-bottom: 20px;
}


.user-details{
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: 1vw;
}

.agent-profile{
    border-radius: 50%;
    width: 11vw;
    height: 21vh;
}

.user-profiless{
    border-radius: 50%;
    /* margin-top: 2%; */
    width: 180px;
    height: 180px;
}

.agent-image{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.user-about{
    line-height: 3.4vh;
    color: rgba(77, 38, 38, 0.7);
    margin-left: 30px;
}

.user-about-name{
    font-weight: 600;
    margin-bottom: -2.5vh;    
    font-size: 16px;  
}


span{
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px;
    font-size: 16px;  
    color: rgba(0, 0, 0, 0.8);
}


.user-history{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 20px;  
    margin-left: 2vw;  
}

.user-instant-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 20px 10px 15px 10px;
    height: 80px;
}

.user-scheduled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 20px 10px 15px 10px;
}


.user-cancelled-request{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid #DADADA;
    align-content: center;
    padding: 20px 10px 15px 10px;
}

.deliv-icons{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.user-flash-display{        
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    font-weight: 600;
    text-align: center;
}

.user-flash-display-no{
    font-size: 14px;
    margin-top: -11px;
}

.user-history-title{
    font-size: 20px;
    font-weight: 500;
    /* margin-left: 10px; */
    margin-bottom: 10px;
}

.user-table{
    margin-left: 3vw;
}


@media only screen and (max-width: 900px){
    .individual-user-screen{
        width: 95%;
        margin-top: auto;
        margin-left: 3vw;
    }

    .user-details{
        display: flex;
        flex-direction: row;
        align-content: baseline;
        justify-content: space-between;
    }

    .user-image{
        width: 20vw;
        height: 12vh;
        border-radius: 50%;
    }
    
    .user-about{
        font-size: 10px;
        margin-left: 0px;
        margin-right: 10px;
    }

    span{
        font-size: 10px;
    }

    
    .user-history{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .deliv-icons{
        width: 3vw;
        height: 3vh;
    }

    .user-instant-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .user-scheduled-request{
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
    }

    .user-cancelled-request{        
        font-size: 10px;
        width: auto;
        height: auto;
        padding: 1px;
        margin-right: 6px;
    }
    
}


