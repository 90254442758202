.assign-delivery{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

    
.toggle-div {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
  .assign-toggle,
  .assign2-toggle {
    margin-top: 4vh;
    width: 23vw;
    padding: 10px 20px;
    border: 1px solid #1aa803;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
    transition: 0.4s;
}



#active {
    background-color: #1aa803;
    color: white;
    margin-right: 0px;
    font-size: 14px;
}
#inactive {
    margin-left: -20px;
    padding-left: 40px;
    color: #1aa803;
    font-size: 14px;
}
  #active2 {
    background-color: #1aa803;
    color: white;
    margin-left: 0px;
    font-size: 14px;
  }
#inactive2 {
    margin-right: -20px;
    padding-right: 40px;
    color: #1aa803;
}
  

.assign-properties{
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.assign-information{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.assign-switch{
    font-size: 14px;
    /* font-weight: 600; */
    display: flex;
    justify-content: center;
}

.assign-searching{
    margin-left: -3%;
    margin-top: 15px;
    outline: none;
    border: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.assign-search-box-container{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;    
}

.assign-search-box{
    display: block;
    margin-top: 2vh;
    padding: 15px 20px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    justify-content: center;
    width: 400px;    
}