.add-admin{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.role-checkbox{
    margin-right: 5px;
}

.add-admin-properties{
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.add-admin-title{    
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.add-admin-form{
    background-color: rgba(217, 217, 217, 0.5);
    border: transparent;
    padding-top:10px;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 31.5vw;
}

.add-admin-form1{
    background-color: rgba(217, 217, 217, 0.5);
    border: transparent;
    padding-top:10px;
    padding-right: 2vw;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-bottom: 1vh;
    font-size: 14px;
    width: 31.5vw;
}


form{
    line-height: 35px;
}

.add-admin-form-page{
    font-size: 15px;
    line-height: 40px;
}


.add-admin-done{    
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-left: 40%;
    margin-bottom: 8vh;
}

.upload{
    width: 50px;
    height: 50px;
}


.add-picture-admin{
    width: 3.5vw;
    height: 3.6vw;
}

/* 
#roles-design{
    background-color: green;
    color: white;
} */

@media only screen and (max-width: 900px){
    .add-admin{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    }

    .add-admin-form{
        width: 80%;
    }
}
