.settings {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
  overflow-x: hidden;
}

.settings-title-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  justify-content: space-between;
}

.settings-props {
  margin-left: 20px;
  margin-top: 20px;
}

.settings-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 20px;
  margin-bottom: 6vh;
}
.settings-right-title {
  background-color: grey;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border: 1px solid greenyellow;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 3.5vh;
  margin-right: 20px;
  /* margin-right: 12vw; */
}

.delivery-settings-medium {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6vh;
}

.delivery-vehicle-medium {
  display: flex;
  flex-direction: row;
}

.disabler {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3vh;
  margin-left: 20px;
  cursor: pointer;
  border: 1px solid #1aa803;
  width: 10vw;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  height: auto;
}

.delivery-bikes {
  border: 0.61457px solid #c4c4c4;
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  margin-right: 1vw;
  margin-left: 1.8vw;
  width: 10vw;
  box-shadow: 0px 6.1457px 6.1457px rgba(0, 0, 0, 0.15);
}

.bikes-medium {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-motor {
  width: 80px;
  height: 70px;
}

.settings-activate {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3vh;
  cursor: pointer;
  border: 1px solid #1aa803;
  width: 10vw;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  height: auto;
  margin: 20px;
}

.update-btn {
  margin: auto;
  padding-left: 3vw;
  padding-right: -2vw;
}

.update-medium {
  /* background-color: #1aa803; */
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  border: none;
  /* width: auto; */
  width: 80%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  height: auto;
}

.update-medium2 {
  background-color: #999999;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  border: 1px solid #999999;
  width: auto;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  height: auto;
}

.settings-activate1 {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3vh;
  margin-left: 20px;
  cursor: pointer;
  border: 1px solid #1aa803;
  width: 10vw;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  height: auto;
}

.settings-data-title {
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
  margin-left: 20px;
}

.distance {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.settings-input {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  /* padding-right: 6vw; */
  width: 21vw;
  padding-left: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 18px;
}

.settings-input1 {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 9vw;
  padding-left: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
}

.settings-input4 {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 12vw;
  /* height: 20px; */
  padding-left: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
}

.settings-input5 {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 9vw;
  padding-left: 10px;
  font-weight: 400;
  font-size: 15px;
  /* color: black; */
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.replace-set {
  background-color: rgba(217, 217, 217, 0.4);
  padding-top: 12px;
  padding-bottom: 12px;
  width: 120px;
  /* appearance: none; */
}
.settings-img {
  width: 120px;
  height: 120px;
  border: 1px solid #1aa803;
}
.settings-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.settings-detail-title {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 11px;
}

.distance-measurement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 120px;
  margin-bottom: 5vh;
}

.distance-measurement1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 5vh;
  width: 52vw;
}

.distance-measurement2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 5vh;
  width: 55vw;
}

.distance-measurement3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 5vh;
  width: 39vw;
}

.percentage-rows {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 5vh;
}

button:active {
  transform: translateY(2px);
}

.delivery-settings-medium2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 6vh; */
  margin-left: 1vw;
}

.delivery-vehicle-medium2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 5vh;
  width: 52vw;
}

.distance1 {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.distance3 {
  display: flex;
  flex-direction: column;
  margin-left: 30%;
  margin-right: 100px;
}

.distance1-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  font-size: 22px;
  margin-bottom: 5px;
  text-align: center;
}

.distance2 {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.delivery-bikes1 {
  border: 0.61457px solid #c4c4c4;
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  width: 5vw;
  box-shadow: 0px 6.1457px 6.1457px rgba(0, 0, 0, 0.15);
  height: fit-content;
}

.bikes-medium1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-motor1 {
  width: 50px;
  height: 40px;
}

.settings-input2 {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  width: auto;
  padding-left: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  /* margin-top: 10px; */
}

.settings-input7 {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 10px;
  width: 20vw;
  font-size: 16px;
  padding-left: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
}

.settings-detail-title2 {
  font-size: 19px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 11px;
}

.delivery-settings-medium {
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-bottom: 6vh;
}

.update-btn {
  /* margin: auto; */
  padding-left: 3vw;
  padding-right: -2vw;
}

.settings-activate4 {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3vh;
  cursor: pointer;
  border: 1px solid #1aa803;
  width: 10vw;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: auto;
  /* margin: 20px; */
}
.shadow-payment-record {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 10px;
}

.settings-activate5 {
  background-color: #1aa803;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 6px;
  cursor: pointer;
  border: 1px solid #1aa803;
  /* width: auto; */
  /* changed the width from 20% to 100% was it affecting anything */
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: auto;
  /* margin: 20px; */
}
.shadow-settings-activate5 {
  background-color: red;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 6px;
  cursor: pointer;
  border: 1px solid red;
  width: 20%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-right: 2%;
  height: auto;
}

/* VIDEO SETTINGS */
.thumbnail-image {
  height: 100px;
  width: 100px;
}
