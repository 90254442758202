.ss-paymensst{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white; 
    /* overflow-y: scroll; */
    height: 86vh;
}

.no-agents-properties{ 
    margin-left: 24px;
    margin-top: 20px;
}
.no-agents-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
}

.no-agents-header{
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
}

.no-agent-search-box-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.no-view{
    margin-left: 20vw;
}


.no-search-box {
    display: block;
    margin-top: 2vh;
    /* margin-left: 3.4vw; */
    padding: 15px 20px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 400px;
}

.no-search-box:focus {
    border: none;
    outline: none;
}




.no-title{
    margin-top: -6vh;
    margin-left: 32vw;
}



.no-agents-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    margin-top: 2.5vh;
    margin-left: 25vw;
}

.delivery-agent-block{
    font-size: 19px;
}

