.DA-payment-history{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh; 
}

.da-payment-props{
    margin-left: 20px;
    margin-top: 20px;
}

.da-payment-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
}

.week-duration {
    font-size: 13px;
    margin: 5px;
}

/* .pay-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
} */

.found-not {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dates {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.dates input {
    width: 100px;
    height: 40px;
}

.found-not img {
    height: 300px;
    width: 300px;
}

.found-not p {
    font-size: 20px;
    margin-bottom: 20px;
}

.result-per-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-right: 15%;
}
.payment-to{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.payment-calender{
    background-color: white;
    color: black;
    border: 1px solid rgba(76, 76, 76, 1);
    border-radius: 4px;
    font-size: 14px;
    /* margin-bottom: 10px; */
    margin-left: 10px;
    padding: 10px 20px 10px;

}

.payment-calender-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.individual-delivery-agents{
    background-color: #1aa803;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.fleet-man{
    background-color: white;
    border: 1px solid #1aa803;
    color: #1aa803;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.payment-vehicle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
    margin-bottom: 20px;
}

.bikes{
    background-color: #1aa803;
    border: 1px solid rgba(218, 218, 218, 1);
    color: white;
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.cars{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: rgba(0, 0, 0, 0.8);
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.buses{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: #1aa803;
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.truck{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: #1aa803;
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}


.payment-week{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 12px;
}

.week1{
    background-color: #1aa803;
    border: 1px solid rgba(218, 218, 218, 1);
    color: white;
    padding-left: 4vw;
    padding-right: 4vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.week2{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: rgba(0, 0, 0, 0.8);
    padding-left: 4vw;
    padding-right: 4vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.week3{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: #1aa803;
    padding-left: 4vw;
    padding-right: 4vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.week4{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: #1aa803;
    padding-left: 4vw;
    padding-right: 4vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.week5{
    background-color: white;
    border: 1px solid rgba(218, 218, 218, 1);
    color: #1aa803;
    padding-left: 4vw;
    padding-right: 4vw;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.week1date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.paid{
    background-color: #F5EC16;
    text-align: center;
    border-radius: 6px;
    margin-right: 6px;
    margin-top: 5px;
    font-size: 13px;
    width: 75px;
}

.not-paid{
    background-color: #C4C4C4B2;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 13px;
    width: 75px;
}


.toggle-div {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

.payment-toggle,
.payment1-toggle {
    /* width: max-content; */
    width: 15vw;
    padding: 10px 20px;
    border: 1px solid #1aa803;
    border-radius: 10px;
    cursor: pointer;
    text-align:center;
    transition: 0.4s;
}

#active {
    background-color: #1aa803;
    color: white;
    margin-right: 0px;
}

#inactive {
    margin-left: -20px;
    padding-left: 40px;
    color: #1aa803;
}
#active2 {
    background-color: #1aa803;
    color: white;
    margin-left: 0px;
}

#inactive2 {
    margin-right: -20px;
    padding-right: 40px;
    color: #1aa803;
    font-size: 14px;
}
  

.arrow-payment{
    text-decoration: none;
    font-weight: 100;
    color: black;
}

.result-per-page1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 15%;
}

.payment-form {
    justify-self: right;
}

.drop-down1 {
    /* display: flex; */
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    list-style: none;
    transition: .5s;
    margin-top: -5.5vh;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
}

.drop-down1 li {
    border-bottom: 1px solid #cccccc;
    text-align: center;
    background: #fbf6f6;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 5px;
    height: 49px;
    font-size: 14px;
}

.drop-down1 li:hover {
    color: #1aa803;
}

@media screen and (max-width: 900px){
    .DA-payment-history{
        margin-top: auto;
    }
}
