.agent-applications{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.application-properties{
    margin-left: 20px;
    margin-top: 20px;
}
.application-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
    margin-bottom: 20px;
}

.application-row{
    margin-top: 20px;
    width: 80vw;
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.application-table{
    margin-left: 2.6vw;
    width: 90%;
    line-height: 4.5vh;
    border-collapse: collapse;
    font-size: 13px;
}

tr:hover{
    background-color: rgba(26, 168, 3, 0.15);
    cursor: pointer;
}

.table-left{
    margin-left: 2vw;
}

tbody{
    cursor: pointer;
    font-size: 13px;
}

td{ 
    color: rgba(0, 0, 0, 0.6);    
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 300;
}



.application-view-pages{
    word-spacing: 3px;
    display: flex;
    flex-direction: row;
}

.more{
    margin-left: 20px;
    margin-right: 20px;
}

.agent-details{
    list-style-type: none;
    text-decoration: none;
}


@media only screen and (max-width: 900px){
    .agent-applications{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    }

    .application-row{
        font-size: 10px;
    }

    .application-header{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .calender{
        margin-left: 18px;
        width: auto;
    }
}
