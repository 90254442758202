#Form-flex-ULogin {
  border-radius: 20px;
  padding: 50px 40px 30px 50px;
  height: 510px;
  background-color: white;
}
#User-form-ULogin {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}
button:disabled {
  opacity: 0.3;
}
#forgot {
  text-align: right;
  align-self: flex-end;
  font-size: small;
  color: #197909;
  /* margin-top: 3px; */
  cursor: pointer;
}
#agree-p {
  text-align: center;
  font-size: small;
  margin-bottom: 70px;
}
#welcome-main {
  margin-bottom: 25px;
}
.policy {
  color: #1aa803;
  cursor: pointer;
}

#welcome{
  word-spacing: 4px;
}

.login-page{
  background-color: white;
  height: 100vh;
  width: 100vw;
}

.login-input{
  /* height: 32px; */
  position: relative;
  font-size: 15px;
  outline: none;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  width: 30vw;
  padding-left: 10px;
  border-style:  1.68043px solid rgba(10, 16, 52, 0.7);
  margin-bottom: 2vh;
}

.login-label{
  font-size: 16px;
  font-weight: 500;
  color: rgba(10, 16, 52, 1);

}

.login-input::placeholder{
  /* padding-left: 20px; */
  font-size: 13px;
}

.login-input{
  font-size: 15px;
  font-weight: 500;
}

.login-btn{
  display: flex;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 5px;
  color:white;
  padding: 10px 60px;
  margin-top: 20px;
  background-color: #1aa803;
  margin: auto;
  margin-top: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.1s;
}

/* button:active{
  transform: translateY(2px);
} */


.mainBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: -10.0909px -7.56816px 31.534px rgba(84, 84, 84, 0.25),
    10.0909px 7.56816px 31.534px rgba(84, 84, 84, 0.36);
  margin: 3% auto 5% auto;
  width: fit-content;
  /* max-width: 70vw; */
  padding: 3px;
}
#DeliveryImage {
  text-align: left;
  color: white;
  padding: 80px 50px 30px 30px;
  background-color: #1faa08;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: -20px;
  height: 100%;
}
#yellow {
  color: yellow;
}


.message{
  color: #1aa803;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2vh;
}