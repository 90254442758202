.all-deliveries {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
}

.all-deliveries-properties {
  /* margin-top: 30px; */
  /* margin-left: 30px; */
  padding-left: 20px;
}

.all-deliveries-title {
  font-size: 26px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 20px;
}

.icon-space {
  margin: 0 5px;
  cursor: pointer;
  color: #666666;
}
.icon-space-less {
  margin: 0 5px;
  cursor: pointer;
  color: #666666;
  opacity: 0.2;
}

.deliveries-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-right: 20px; */
  padding: 0 90px 0 30px;
}

.delivery {
  text-align: center;
  align-items: center;
  height: 30px;
  margin-right: 50px;
  border-radius: 5px;
  border: 1px solid black;
}

.back {
  padding-top: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  font-weight: lighter;
  /* font-weight: 100; */
  /* margin-left: 20px; */
}

.back2 {
  padding-top: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  font-weight: lighter;
  margin-left: 10px;
}

.back3 {
  padding-top: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  font-weight: 100;
}

.mask {
  width: 10px;
  height: 10px;
  margin-left: 9px;
}

.delivery-search-box-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 1vh;
  margin-bottom: 4vh;
}

.delivery-searching {
  margin-top: 2%;
  margin-left: -4%;
  padding-right: 15px;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;

  height: 20px;
  position: absolute;
  right: 0;
  top: 40%;
}

.all-deliveries-drop-down {
  list-style: none;
  /* width: 10rem; */
  width: auto;
  /* margin-left: -3vw; */
  position: absolute;
  left: 0;
  padding: 0 !important;
}

.all-deliveries-drop-down li {
  padding: 10px 0px;
  border-bottom: 1px solid #cccccc;
  width: 10rem;
  text-align: center;
  background: #fbf6f6;
  cursor: pointer;
}

.all-deliveries-drop-down li:hover {
  color: #1aa803;
}

.deliveries-App {
  /* position: fixed; */
  position: relative;
  /* width: 15rem; */
  font-size: 12px;
  align-items: center;
  display: inline;
  background-color: transparent;
  /* padding-left: 3px; */
  /* padding-right: 3px; */
  overflow-y: inherit;
  /* margin-left: -16vw; */
  cursor: pointer;
}

.deliveries-boom {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid rgba(76, 76, 76, 1);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 300;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.delivery {
  position: relative;
  width: 10rem;
  border: 1px solid black;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .all-deliveries {
    width: 94%;
    margin-top: auto;
    margin-left: 3.5vw;
  }

  .deliveries-top {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .delivery {
    margin-top: 4%;
  }

  .search-box {
    width: auto;
  }

  .delivery-row {
    font-size: 10px;
  }
}
