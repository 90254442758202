.parent-container{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-right: 8vw;
    margin-top: -83vh;
    padding: 85px;
    margin-left: 23vw;
}

.main-container{
    display: flex;
    flex-direction: column;
    background-color: #1aa803;
    color: white;
    font-size: 18px;
    height: 380px;
    width: 450px;
    align-items: center;
    padding: 10px;
}

.main-container p {
    color: white;
}