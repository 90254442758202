.individual_referral{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
    padding: 1rem 3rem;
}

.ref_ind_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}

.ref_ind_name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ref_ind_name > p{
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;    
    margin-left: 10px;
}

.ref-back{
    cursor: pointer;
    margin-right: 2vw;
}

.ind_qual{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
}

.ind_qual > p{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);    
}

.ind_qual > span{
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);    
}

.target_main_ctn{
    width: 95%;
    margin: 1rem auto;
}

.target_ctn > p{
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);    
    margin-top: 2rem;
}

.target_props_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 1rem;
    height: 55vh;
    overflow-y: scroll;
    /* scrollbar-width: none; */
}

/* .target_props_grid::-webkit-scrollbar {
    display: none;
} */


.target_props_ctn {
    border: 0.4px solid rgba(26, 168, 3, 1); 
    padding: 4%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    width: 20vw;
}

.target_props_ctn > p {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.ref_amt p{
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.ref_date p{
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.ref_date_amt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}

.ref_amt span {
    color: rgba(26, 168, 3, 1);
    text-decoration: underline;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.individual-agent-props{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 97%;
    margin: 1rem auto;
}

.ind-agent-props{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ind-agent-details{
    width: 93%;
}

.ind-agent-rev{
    width: 33%;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 80vh;
}

.ind-agent-rev::-webkit-scrollbar {
    display: none;
}


.ind-agent-content > h3 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    
}

.ind-agent-content > p{
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);    
}

.ind-agent-content > p > span{
    color: rgba(0, 0, 0, 0.6);
    font-weight: lighter;
}

.ind-agent-acts{
    border-radius: 1px;
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 5px;
}

.ind-agent-earns{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.4rem;
}

.ind-agent-earns > img{
    margin-right: 10px;
}

.ind-agent-text > p{
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;    
}


.ind-agent-rev > h1{
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: rgba(10, 16, 52, 1);    
}


.ind-agent-history{
    margin: 3rem 0;
}

.ind-agent-history > h1{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);    
}