.no-agents-application{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.no-agents-application-properties{
    margin-left: 24px;
    margin-top: 20px;
}
.no-agents-application-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 40px;
}


/* .none1-found{
    width: 330px;
    height: 300px;
    margin-left: 3vw;
    
} */

.no1-found{
    width: 350px;
    height: 350px;
}

.no-title11{
    margin-top: -20px;
    font-size: 20px;
}

.align-not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}