.otp{
    background-color: white;
    width: 100%;
    height: 100vh;
}

.otp-body{
    width: 70vw;
    height: 70vh;
    margin-left: 15vw;
    margin-top: 9vh;
    align-content: center;
    text-align: center;
    border: 1px solid #0A1034;
}

.ellipse{
    width: 100px;
    height: 100px;
    float: right;
}

.otp-title{
    padding-top: 4vh;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    padding-bottom: 3vh;
    color: #0A1034;
}

.otp-sms{
    margin-top: 6vh;
    margin-bottom: 7vh;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    color: #0A1034;
}

.otp-field{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 5vh;
}

.token-field-admin{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 6vw;
}

.token-expiry{
    text-align: center;
    font-weight: 300;
    word-spacing: 4px;
}

.token-timer{
    color: #1aa803;
}


.otp-done{
    background-color: #1aa803;
    color: white;
    width: 20vw;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-top: 5.5vh;
    /* margin-left: 25vw;    */
    text-align: center;
}