/* .App{
    display: flex;
    flex: 1;
    width: 100vw;
    height: 100%;
} */




*{
    margin: 0;
    padding: 0;
}
body{
    overflow-y: hidden;

    background-color: rgba(26, 168, 3, 0.15);
}
