.change-password{
    margin-top: -95vh;
}


.change-password-props{
    background-color: white;
    width: 76vw;
    margin-left: 20vw;
    border-radius: 4px;
    height: 90vh;
    align-items: center;
    justify-content: center;
}

.name-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.save-changes1{ 
    background-color: #1aa803;
    color: white;
    width: 25vw;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-top: 6.5vh;
    /* margin-left: 25vw; */
}

.change-password-style{
    padding-top: 7vh;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.new-no{
    font-size: 12px;
}



.new-password-styling{
    width: 4vw;
    height: 5vh;
}


.new-phone-password{
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    width: 40vw;
    justify-items: center;
    margin-top: 2px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    outline: none;
    font-size: 15px;
}

.new-password-main{
    display: flex;
    flex-direction: row;
}

.save-changes{ 
    background-color: #1aa803;
    color: white;
    width: 25vw;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-top: 6.5vh;
    /* margin-left: 25vw; */
}

.form{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.chizy-button {
    align-self: center;
    background-color: #1aa803;
    color: white;
    width: 25vw;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
}