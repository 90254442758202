.change-number{
    text-align: center;
    margin-top: -75vh;
    margin-left: 40vw;
    overflow-y: scroll;
}

.changed-number-props{
    padding-top: 5vh;
    width: 30%;
    padding-bottom: 9vh;
    text-align: center;
    background-color: white;
}

.success-icon-number{
    margin-top: 4vh;
    margin-bottom: 5vh;
    width: 5vw;
    height: 9vh;
}

.message-success{
    font-weight: 300;
}