.profile-prop {
    display: flex;
    align-items: center;
    margin: 5px 0;
}
.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background-color: black; */
    margin-right: 15px;
}
.profile-text h3 {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.8);
}
.profile-text h6 {
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
}

.support-profile-pic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}