.no-blocked{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.no-blocked-properties{
    margin-left: 25px;
    margin-top: 20px;
}
.no-blocked-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
}

.no-blocked-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.no-search-box-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.no-search-box1 {
    display: block;
    margin-top: 2vh;
    /* margin-left: 24.4vw; */
    padding: 15px 20px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 400px;
}

.no-search-box1:focus {
    border: none;
    outline: none;
}


.no-blocked-found{
    width: 330px;
    height: 300px;
    margin-left: 2vw;
}

.no-title{
    margin-top: -6vh;
    margin-left: 30vw;
}



.no-blocked-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 2.5vh;
    margin-left: 25vw;
}


