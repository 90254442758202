.no-report{
    /* width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%; */
    background-color: white; 
    height: 86vh;
    /* margin-top: 10vh; */
} 

.no-report-properties{
    margin-left: 24px;
    margin-top: 20px;
}
.no-report-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: -300px;
    text-align: center;
}


.no-report-details{    
    margin-left: -270px;
    text-align: center;
}

.no-reportss{
    margin-top: 30vh;
}