@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.referral {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
}

.referal-main {
  padding: 2% 5%;
}

.referal-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;
}

.referal-user-agent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}

.referal-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 5%;
  justify-content: space-between;
}

.referal-settings > p {
  font-size: 0.938vw;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin-right: 0.6rem;
}

.referal-settings > img {
  cursor: pointer;
}

.referal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.referal-back {
  margin-right: 10px;
  font-weight: lighter;
}

.referal-title > h1 {
  font-size: 1.667vw;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
}

.referal-btn-ctn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(31, 170, 8, 0.09);
  padding: 6px;
  width: fit-content;
  margin: 1rem auto;
}

.referal-btn-ctn > button {
  border: none;
  background-color: none;
  font-size: 0.938vw;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  width: 250px;
}

.reward-all-drop select {
  background-color: rgba(31, 170, 8, 0.09);
  border: 1px solid rgba(31, 170, 8, 0.6);
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.referal-btn-ctn1 {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  background-color: rgba(31, 170, 8, 0.09);
  padding: 0.5% 0.1% 0.5% 1%;
  width: fit-content;
  margin: 2rem auto;
}

.referal-btn-ctn1 > button {
  border: none;
  background-color: none;
  font-family: "Poppins";
  font-size: 0.938vw;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  width: 150px;
}

.referal-modal {
  position: absolute;
  top: 6%;
  right: 2%;
  transform: none;
  background-color: white;
  padding: 1.5rem 1rem;
  width: 25vw;
  height: fit-content;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 4px 34px 0px rgba(153, 153, 153, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.referal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.referal-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.referal-input {
  width: 120px;
  padding: 5px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  border: 0.2px solid rgba(119, 119, 119, 1);
}

.referal-icon {
  cursor: pointer;
  margin-left: -30%;
}

.referal-input::-webkit-outer-spin-button,
.referal-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.referal-set-pop {
  width: 90%;
}

.pop-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 3% 0;
  margin-left: 10%;
}

.pop-title {
  display: flex;
  flex-direction: row;
}

.pop-title > span {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color: rgba(26, 168, 3, 1);
  margin-right: 1rem;
  cursor: pointer;
}

.pop-title > p {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  color: rgba(26, 168, 3, 1);
}

.referal-set-pop > h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 54px;
  text-align: left;
  margin-top: 7%;
}

.referal-set-pop > button {
  display: flex;
  justify-content: center;
  background-color: rgba(26, 168, 3, 1);
  color: rgba(255, 255, 255, 1);
  margin: 2rem auto 0;
  border-radius: 5px;
  padding: 7px 2.4rem;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}

.reward-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-modal {
  position: relative;
  background-color: white;
  width: 30%;
  max-width: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  outline: none;
  height: auto;
}

.reward-modal > h1 {
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: rgba(26, 168, 3, 1);
  padding-top: 5%;
  margin-bottom: 4%;
}

.reward-modal > h2 {
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  width: 70%;
  margin: 10% auto;
}

.modal-btn {
  width: 50%;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-top: 0.99px solid rgba(204, 202, 202, 1);
  border-left: 0.99px solid rgba(204, 202, 202, 1);
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  padding: 2%;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom-left-radius: 10px;
}

.modal-btn:hover {
  color: rgba(26, 168, 3, 1);
}

.modal-btn1 {
  width: 50%;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-top: 0.99px solid rgba(204, 202, 202, 1);
  border-left: 0.99px solid rgba(204, 202, 202, 1);
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  padding: 2%;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom-right-radius: 10px;
}

.modal-btn1:hover {
  color: rgba(26, 168, 3, 1);
}

.reward-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  /* margin-top: 16px; */
}

.square-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 4px 0;
}

.square-radio input[type="radio"] {
  display: none;
}

.tick-mark {
  width: 46px;
  height: 24px;
  border: 1px solid #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 26px;
  color: rgba(26, 168, 3, 1);
  border-radius: 2px;
  margin-bottom: 4px;
}

.square-radio input[type="radio"]:checked + .tick-mark {
  border-color: green;
}

.reward-label {
  font-size: 14px;
  color: #333;
}

.reward-details {
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.5;
  width: fit-content;
}

.reward-status {
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  text-align: center;
}

.reward-status.paid {
  /* background-color: #4CAF50;
    color: #fff; */
  color: #4caf50;
  text-decoration: underline;
  height: 40px;
}

.reward-status.not-paid {
  background-color: #ff6347;
  color: #fff;
  height: 40px;
}

.no-referal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 45vh;
}

.no-referal > p {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: rgba(126, 126, 126, 1);
}

.referal-numbering {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgba(49, 49, 49, 0.8);
  width: 15%;
  align-content: flex-end;
  margin: auto;
  margin-top: 20vh;
}

.ref-page-numbers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
}

.ref-page-active {
  background-color: rgba(26, 168, 3, 1);
  padding: 2px 3px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 1);
}

.payment-calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.payment-calendar1 {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid #4caf50; /* Green border */
  border-radius: 8px;
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Text color */
  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.payment-calendar1:hover {
  border-color: #388e3c; /* Darker green on hover */
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.payment-calendar1:focus {
  border-color: #1b5e20; /* Even darker green on focus */
  background-color: #fff;
  box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.3); /* Focus ring */
}

.payment-calendar1::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(40%) sepia(60%) saturate(200%) hue-rotate(100deg)
    brightness(95%) contrast(90%);
}

.reward-ref-img {
  margin-right: 2vw;
  height: 15px;
  width: 3px;
}

.reward-dropdown-menu {
  position: absolute;
  right: 5%;
  background-color: rgba(221, 242, 217, 1);
  border: 1px solid rgba(221, 242, 217, 1);
  border-radius: 6px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  width: 145px;
}

.reward-dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
  list-style-type: none;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  color: rgba(41, 45, 50, 1);
}

.reward-dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.ref-search-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3rem 0;
  align-items: center;
}

.ref-search {
  position: relative;
  height: fit-content;
}

.ref-search > img {
  position: absolute;
  top: 50%;
  left: 5%;
  cursor: pointer;
}

.reward-search-box {
  display: block;
  height: auto;
  margin-top: 20px;
  padding: 15px 15px 15px 3.5rem;
  background: rgba(218, 218, 218, 0.4);
  border: none;
  border-radius: 4px;
  width: 400px;
  height: auto;
}

.reward-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reward-checkbox > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: rgba(41, 45, 50, 1);
  margin-left: 10px;
}
