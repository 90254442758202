.blocked{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.blocked-properties{
    margin-left: 25px;
    margin-top: 20px;
}
.blocked-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 20px;
}

.blocked-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.blocked-search-box {
    display: block;
    /* margin-top: 3vh;
    margin-bottom: 5vh; */
    /* margin-left: 24.4vw; */
    padding: 15px 20px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 100%;
}

.search-box:focus {
    border: none;
    outline: none;
}


.found{
    width: 330px;
    height: 300px;
    margin-left: 25vw;
}

.title{
    margin-top: -6vh;
    margin-left: 32vw;
}

.blocked-searching{
    /* margin-top: 5%;
    margin-left: -9%; */
    outline: none;
    border: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 28%;
}

.blocked-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 2.5vh;
    margin-left: 25vw;
}
.blocked-search-box-container {
    height: 50px;
    margin-bottom: 10px;
    position: relative;
    width: 80%;
    max-width: 400px;
}

.unblock{    
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-right: 2.2vw;
    margin-bottom: 3px;
    cursor: pointer;
}

/* .blocked-header{
    padding-left: 20px;
} */



@media only screen and (max-width: 900px){
    .blocked{
        width: 92%;
        margin-top: auto;
        margin-left: 4vw;
    }

    .search-box{
        width: auto;
    }
}
