.administrator {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
}

.administrator-properties {
  margin-left: 20px;
  margin-top: 20px;
  padding-left: 3px;
}

.application-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  margin-right: 20px;
}

.admin-searching {
  background-color: transparent;
  margin-left: -9%;
  outline: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.calender {
  height: 30px;
  margin-right: 50px;
  border-radius: 5px;
  border: 1px solid black;
}

.administrator-title {
  font-size: 26px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 20px;
}

.admin-search-box-container {
  display: flex;
  flex-direction: row;
  margin-left: -5%;
}
.admin-search-box {
  display: block;
  margin: 20px;
  padding: 15px 20px;
  background: rgba(218, 218, 218, 0.4);
  border: none;
  border-radius: 4px;
  width: 450px;
}

.add-administrator-btn {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  border: 1px solid #1aa803;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.admin-image-icon {
  border-radius: 40px;
  margin-top: 5px;
}

.admin-table {
  width: 80%;
  line-height: 4.5vh;
  margin-left: 3vw;
  border-collapse: collapse;
  font-size: 13px;
}

.admin-table-pic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 0.5vh;
  margin-bottom: 1.5vh;
  margin-left: 0.5vw;
  object-fit: cover;
}

.admin-search-box-container {
  /* margin-left: -25vw; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2vh;
  margin-right: 5vw;
}

.admin-block {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  border: 1px solid #1aa803;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.menu {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.menu-item {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  border-bottom: 1px solid rgb(187, 187, 187);
}
.menu-item:hover {
  color: #2980b9;
}

.drop-down {
  list-style: none;
  transition: 0.5s;
  margin-top: -5.5vh;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
  margin-left: 0.6vw;
}

.drop-down li {
  border-bottom: 1px solid #cccccc;
  text-align: center;
  background: #fbf6f6;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 5px;
  height: 30px;
  font-size: 14px;
  margin-left: -2vw;
}

.drop-down li:hover {
  color: #1aa803;
}

.admin-App {
  position: absolute;
  width: 13rem;
  font-size: 10px;
  align-items: center;
  display: inline;
  margin-top: -1.5vh;
  margin-left: -3.3vw;
  background-color: transparent;
  padding-left: 3px;
  padding-right: 3px;
  overflow-y: inherit;
}

.popup-click {
  position: relative;
}

.chizys-input {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  /* padding-right: 6vw; */
  width: 60px;
  height: 15px;
  padding-left: 6px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 12px;
}

.chizys-button {
  background-color: #1aa803;
  color: white;
  border: 1px solid #1aa803;
  border-radius: 5px;
}

@media only screen and (max-width: 900px) {
  .administrator {
    width: 94%;
    margin-top: auto;
    margin-left: 3.5vw;
  }

  .administrator-row {
    font-size: 10px;
  }

  .admin-search-box-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .admin-search-box {
    width: auto;
  }

  .add-admin-btn {
    font-size: 13px;
  }
}
