.sidebarWrapper{
    width: 16%;
    height: 92.5vh;
    background-color: rgba(255, 255, 255, 1);
    /* overflow: auto; */
    overflow: auto;
    z-index: 999;
}

.sidebarWrapper::-webkit-scrollbar {
    display: none;
}

.sidebarList{
    padding: 0;
    display: flex;
    flex-direction: column;
}

.sidebarMenu{
    color: white;
    text-decoration: none;
    line-height: 10px;
    align-items: center;
}

.sidebarTitle{
    color: rgba(0, 0, 0, 0.6);
    font-size: 19px;
    font-weight: 500;
    margin-top: 22px;
    margin-bottom: 7px;
    margin-left: 18px;
}

.sidebarListItem{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 300;
    text-decoration: none;
    padding-left: 29px;    
    padding-top: 12px;
    padding-bottom: 9px;
    font-size: 16px;
    margin: 0;
    margin-top: 0.3vh;
    margin-bottom: 0.3vh;
    display: flex;
    flex-direction: row;
    /* align-items: baseline; */
    align-items: center;
}

.sidebarListItem1{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 300;
    text-decoration: none;
    margin-top: 4.3vh;
    padding-top: 10px;
    padding-bottom: 5px;
    line-height: 24px;
    padding-left: 25px;
    font-size: 17px;
    display: flex;
    flex-direction: row;
}

.icons-space{
    margin-right: 6px;
    font-size: 20px;
}

.icons-space2{    
    margin-right: 6px;
    font-size: 20px;
}

.icons-space3{    
    margin-right: 6px;
    font-size: 25px;
}  

.sidebarListItem1:hover{
    background-color: rgba(26, 168, 3, 0.15);
    /* transform: scale(1.1);
    transition: .5s; */
}

.sidebarListItem:hover{
    background-color: rgba(26, 168, 3, 0.15);
    /* transform: scale(1.1);
    transition: .5s; */
}



.hamburger-menu-container{
    flex: 1;
    display: none;
    align-items: center;
}

.hamburger-menu{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger-menu div{
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #1fa008;
    position: relative;
    z-index: 1001;
    transition: .5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after{
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #1fa008;
    transition: .5s;
    border-radius: 3px;
}


.hamburger-menu div:before{
    transform: translateY(-7px);
}

.hamburger-menu div:after{
    transform: translateY(7px);
}

.new-dev-req {
    display: flex !important;
}

.new-rep {
    display: flex !important;
}

.new-reg-app {
    display: flex !important;
}

.new-msg {
    display: flex !important;
}

#check{
    position: absolute;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 9000;
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 2.5vh;
    cursor: pointer;
    opacity: 0;
    display: none;
}

#check:checked ~ .hamburger-menu-container
.hamburger-menu div{
    background-color: transparent;
}

#check:checked ~ .hamburger-menu-container
.hamburger-menu div:before{
   transform: translateY(0) rotate(-45deg);
}

#check:checked ~ .hamburger-menu-container
.hamburger-menu div:after{
   transform: translateY(0) rotate(45deg);
}

@media only screen and (max-width: 900px){
    .hamburger-menu-container{
        display:flex;
    }

    #check{
        display: block;
    }
    
    .sidebarWrapper{
        position: fixed;
        width: 100%;
        background-color: rgba(26, 168, 3, 1);
        align-items: center;
        align-content: center;
        color: white;
        transform: translateX(-100%);
        transition: .65s;
    }

    #check:checked ~ .sidebarWrapper{
        transform: translateX(0);
    }

    .sidebarListItem{
        color: white;
        justify-content: center;
    }

    .sidebarListItem1{
        color: white;
        justify-content: center;
    }
    
    .sidebarTitle{
        color: white;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
} 


