.settings {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 86vh;
  overflow-x: hidden;
}

.settings-title-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  justify-content: space-between;
}

.video-settings-data-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 12px;
}

.settings-title {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
}

.settings-props {
  margin-left: 20px;
  margin-top: 20px;
}

.settings-link-input {
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 15vw;
  padding-left: 10px;
  font-weight: 400;
  font-size: 15px;
  /* color: black; */
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 10px;
}

.thumbnail-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.settings-grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Adjust the minmax values as needed */
  grid-gap: 20px; /* Adjust the gap between items */
  margin-bottom: 20px;
}

.settings-center-column {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  max-width: 300px;
}

.hide-no-file-chosen{
  color: transparent;
}