.no-users{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}
.shadow-no-users {
    /* width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%; */
    background-color: white;
    /* overflow-y: scroll; */
    /* height: 86vh; */
}
.no-users-properties{
    margin-left: 20px;
    margin-top: 20px;
}
.shadow-no-users-properties {
    
}

.no-users-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
}

.no-users-search-box-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 50px;
}


.no-users-search-box {
    display: block;
    margin-top: 2vh;
    margin-left: 3.4vw;
    padding: 20px 10px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 40%;
}

.no-ags-search-box{
    display: block;
    margin-top: 2vh;
    /* margin-left: 3.4vw; */
    padding: 20px 10px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 40%;
}

.no-search-box:focus {
    border: none;
    outline: none;
}



.no-users-found{
    width: 330px;
    height: 300px;
    margin-left: 44%;
    align-items: center;
    justify-content: center;
}

.no-title{
    margin-top: -6vh;
    margin-left: 32vw;
}

.no-user-found-title{
    margin-top: -2vh;
    margin-left: 41%;
}

.no-fleet-found-title{
    margin-top: -2vh;
    margin-left: 39%;
}

.no-searching{
    margin-top: 2%;
    margin-left: -54%;
    outline: none;
    border: none;
    align-items: center;
    justify-content: center;
}

.no-users-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 2.5vh;
    margin-right: 10px;
    /* float: right; */
}


