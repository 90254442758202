.edit-profile{
    margin-top: -95vh;
}

.edit-profile-props{
    background-color: white;
    width: 76vw;
    margin-left: 20vw;
    border-radius: 4px;
    height: 94vh;
    overflow-y:scroll;
}



.edit-profile-title{
    margin-top: 8vh;
    margin-bottom: 1vh;
    font-size: 17px;
    text-align: center;
    font-weight: 400;
}

.edit-profile-picture{
    /* margin-left: 43%; */
    margin-top: -2%;
}

.align-main-admin-profile{
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit-profile-main{
    margin-top: 30px;
    border: 4px solid #1aa803;
    padding-top: 3px;
    width: 10vw;
    border-bottom-right-radius: 1px solid white;
    align-items: center;
    justify-content: center;
    height: 20vh;
    text-align: center;
    border-radius: 50%;
}

.admin-change-pic{
    border-radius: 50%;
    width: 8.6vw;
    height: 17.6vh;
}

.role-profile{
    margin-top: 1vh;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    color: #0A1034;
}

.rank{
    margin-top: 1vh;
    padding: 10px 10px 10px 10px;
    text-align: center;
    font-weight: 300;
    color: #666666;
    background-color: #DDF2D9;
    border-radius: 5px;
    width: fit-content;
    /* margin-left: 35vw; */
}

.admin-number{
    margin-top: 2vh;
}



.admin-no{
    font-size: 12px;
}

.admin-number-props{
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    width: 39vw;
    justify-items: center;
    margin-top: 6px;
    padding-top: 7px;
    padding-bottom: 9px;
    padding-left: 10px;
    font-weight: 400;
    /* color: rgba(0, 0, 0, 0.3); */
}

.admin-name-props{
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    width: 9vw;
    justify-items: center;
    margin-top: 6px;
    padding-top: 7px;
    padding-bottom: 9px;
    padding-left: 10px;
    font-weight: 400;
    /* color: rgba(0, 0, 0, 0.3); */
    border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.nigerian-flag{
    width: 30px;
    height: 20px;
}

.admin-number-styling{
    width: 3vw;
    height: 3vh;
}

.change-edit{
    margin-top: 2.3vh;
    margin-left: 0.5vw;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}


.admin-phone-number{
    background-color: #ECECEC;
    display: flex;
    align-items: center;
    width: 39vw;
    justify-items: center;
    margin-top: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-weight: 400;
    color: black;
    border: none;
    outline: none;
}

.admin-number-main{
    display: flex;
    flex-direction: row;
}


.admin-name-styling{
    width: 33vw;
    padding-left: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.nigerian-code{
    font-size: 11px;
}

.save-changes{ 
    background-color: #1aa803;
    color: white;
    width: 25vw;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
    /* margin-left: 25vw; */
}

.save-changes3{    
    background-color: #1aa803;
    color: white;
    width: 25vw;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
    margin-bottom: 30px;
    /* margin-left: 25vw; */
}