.individual-fleet-payment{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
    overflow-x: hidden;
}


.individual-fleet-details{
    padding-left: 10px;
    padding-bottom: 20px;
}


.week-no{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    padding-left: 10px;
    margin-left: 6vw;
}

.payment-logo{
    background-color: #C4C4C4B2; 
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 50px 10px;
    border-radius: 4px;}

.fleet-details{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.fleet-image{
    border-radius: 100px;
    width: 140px;
    height: 140px;
}

.fleet-about{
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}

span{
    font-weight: 600;
    word-spacing: 5px;
    letter-spacing: 0.9px;
    font-size: 16px;  
    color: rgba(0, 0, 0, 0.8);
}

.individual-fleet-row{
    margin-top: 20px;
    width: 80vw;
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
}

.individual-fleet-view-pages{
    word-spacing: 3px;
    display: flex;
    flex-direction: row;
}

.more{
    margin-left: 20px;
    margin-right: 20px;
}

.fleet-payment{
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 30px;
}

.fleet-vehicle-payment{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.bike{
    background-color: #1AA803; 
    border: none;
    color: white;
    padding: 10px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.fleet-weekly-earning{
    background-color: #1AA803; 
    float: right;
    display: flex;
    border: none;
    color: white;
    padding: 10px 60px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 20px 50px 10px;
    border-radius: 4px;

}

@media screen and (max-width: 900px){
    .individual-fleet-payment{
        margin-top: auto;
        width: 95%;
        margin-left: 3.5vw;
    }

    .week-no{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

    .fleet-details{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    span{
        font-size: 11px;
    }

    .fleet-image{
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }

    .fleet-vehicle-payment{
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }
}
