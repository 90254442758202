.support {
  width: 81.7%;
  margin-left: 17.4%;
  margin-right: 15%;
  margin-top: -89vh;
  background-color: white;
  overflow-y: scroll;
  height: 87vh;
  padding: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photo_display {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 10;
  width: 100px;
  height: 100px;
}

.support-chat {
  position: absolute;
  top: 10%;
  right: 2%;
  left: 17%;
  bottom: 3%;
}
.loading-pages1 {
  position: absolute;
  top: 50%;
  left: 50%;
}

.support-to {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  /* margin-bottom: 20px; */
  /* margin-top: 20px; */
  position: absolute;
  top: 10%;
  left: 52%;
  z-index: 1;
}

#active-support {
  background-color: #1aa803;
  color: white;
  margin-right: 0px;
}

#inactive-support {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 40px;
  padding-right: 40px;

  color: #1aa803;
  background-color: white;
}
#active-support2 {
  background-color: #1aa803;
  color: white;
  margin-left: 0px;
}

#inactive-support2 {
  margin-right: -20px;
  padding-right: 40px;

  color: #1aa803;
  background-color: white;
}

#inactive-support3 {
  margin-left: -20px;
  padding-left: 40px;
  background-color: #1aa803;
  color: white;
  /* border: 1px solid white; */
}
.support-props {
  margin-left: 20px;
  margin-top: 20px;
  background-color: white;
  width: 79.7%;
  margin-left: 18.6%;
  margin-right: 16%;
  margin-top: -96vh;
  height: 80vh;
}

.support-system {
  margin-left: 25vw;
  margin-right: 16%;
  background-color: none;
  margin-bottom: 10px;
}

.switch-btn-support {
  background-color: #1aa803;
  color: white;
  padding-left: 22px;
  padding-right: 20px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border: 1px solid #1aa803;
  font-size: 15px;
  font-weight: 600;
}

.switch-btn1-support {
  background-color: white;
  border: 1px solid #1aa803;
  color: #1aa803;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
}

p {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
}

.chat-admin {
  padding: 1em 0.1em !important;
  background-color: #e8f4e3;
}
.chat-wrapper {
  display: flex;
  background: #ffffff;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  /* overflow-y: scroll; */
  overflow: hidden;
  padding: 1em 0;
}
.chat-left-side {
  width: 40%;
  height: 80vh;
  overflow: auto;
}
.Chat-profile {
  margin-bottom: 12px;
  padding: 5px 8px;
  margin-left: 13px;
  cursor: pointer;
  position: relative;
}
.unread-message-count {
  position: absolute;
  right: 5%;
  bottom: 8%;
  background-color: #f5ec16;
  padding: 3px 8px !important;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
}
.chat-margin {
  margin-left: 13px;
}
.active-chat {
  background: rgba(26, 168, 3, 0.15);
  border-radius: 8px;
}
.Chat-profile p {
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  padding-right: 2.5px;
}
/* .chat-report {
    
} */
.chat-report img {
  width: 20px;
  position: absolute;
  right: 5px;
  top: 24%;
}
.time-of-msg {
  position: absolute;
  right: 0;
  top: 10%;
  font-size: 10px !important;
  line-height: 12px !important;
  font-weight: 200 !important;
}
.no-of-messages {
  font-weight: 400;
  font-size: 7px;
  line-height: 10px;
  background: #f5ec16;
  border-radius: 4px;
  width: 15px;
  text-align: center;
  position: absolute;
  right: 6px;
  top: 38%;
}
.Chat-profile:hover {
  background-color: rgba(26, 168, 3, 0.15);
  border-radius: 8px;
}
.chat-left-side-top {
  margin-bottom: 20px;
  overflow-y: auto;
}

.chat-left-side h5 {
  position: relative;
  text-align: center;
  padding: 0 10px;
}

.resovled-chat {
  position: relative;
  padding: 3px 5px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.resovled-chat:hover {
  background-color: rgba(26, 168, 3, 0.15);
  border-radius: 8px;
}
.resovled-chat p {
  font-size: 10px !important;
  line-height: 12px !important;
  font-weight: 200 !important;
  color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 20%;
  right: 0;
}

.main-chat-discussion {
  margin-left: 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.profile-picture1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.support-profile-pic1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

/* code for the right side of the chat page  */
.chat-right-side {
  width: 100%;
  padding: 1em;
  position: relative;
}
.your-profile h3 {
  font-weight: 400;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 15px;
  margin-bottom: 2px;
}
.your-profile h6,
.message-header h6 {
  font-weight: 300;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
  margin-top: 2px;
}
.message-header {
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
}
.message-header h6 {
  font-weight: 400 !important;
  position: relative;
  text-align: center;
  color: rgba(26, 168, 3, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 30%;
  margin: 0 auto;
}
.message-header img {
  width: 20px;
  position: absolute;
  top: -20%;
}
.messages-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  height: 60vh;
  background-color: rgba(219, 214, 214, 0.723);
  border-radius: 6px;
}
.messages-wrapper::-webkit-scrollbar {
  display: none;
}

.date-of-msg {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  /* color: rgba(0, 0, 0, 0.6); */
  color: white;
  margin-top: 8px;
}
.shadow-date-of-msg {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  /* margin-bottom: 5px; */
  margin-top: 8px;
}

.incoming-msgs p,
.outgoing-msgs p {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 10px;
  /* max-width: 70%; */
  /* background: rgba(218, 218, 218, 0.3); */
  background-color: transparent;
  word-break: break-word;
}
.incoming-msgs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.outgoing-msgs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.outgoing-msgs p {
  color: white;
}

/* styling for the sending of a  message */
.chat-section {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 3%;
  background-color: white;
  width: 40%;
  right: 12%;
}

.shadow-chat-section {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 3%;
  background-color: white;
  width: 40%;
  right: 20%;
}

.typing-bar input {
  padding-left: 20px !important;
  margin: 0 !important;
  height: 40px;
  border-radius: 10px;
  outline: none;
  border: none;
  background: rgba(196, 196, 196, 0.3);
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  width: 100%;
}
.attachment,
.send-message {
  width: 35px;
  /* margin-left: 7px; */
}
.attachment {
  margin-right: 5px;
}
.attachment img,
.send-message img {
  width: 100%;
  cursor: pointer;
}
.chat-icons {
  display: flex;
  align-items: center;
}
.typing-bar {
  flex: 1;
}
.shadow-typing-bar {
  height: 40px;
  width: 70%;
}

.shadow-text-area {
  width: 100%;
  height: 40px;
  padding: 10px !important;
  border-radius: 7px !important;
}
.shadow-chat-right-side-wrapper {
  width: 70%;
  margin: 0 auto;
  height: 90%;
  margin-top: 20px;
}
.shadow-label-text {
  /* font-size: 11px; */
  width: 35px;
}

@media screen and (max-width: 900px) {
  .support {
    margin-top: auto;
  }
}

/* shadow style change if not pleased  */

.shadow-outgoing-msg-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
.shadow-incoming-msg-container {
  display: flex;
  justify-content: flex;
  padding: 0 20px;
}

.shadow-outgoing-msg-wrapper {
  max-width: 350px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  background-color: #1aa803;
  border-radius: 10px;
  margin: 5px 0;
  padding: 8px 15px;
}

.shadow-incoming-msg-wrapper {
  max-width: 350px;
  background-color: white;
  border-radius: 10px;
  margin: 5px 0;
  padding: 8px 15px;
}
.shadow-incoming-msg-p {
  text-align: left;
}
