.users{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.users-properties{
    margin-left: 20px;
    margin-top: 20px;
}
.users-title{
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 20px;
}

.users-table{
    margin-left: 2vw;
}

.search-box {
    display: block;
    height: auto;
    margin-top: 20px;
    padding: 15px 15px;
    background: rgba(218, 218, 218, 0.4);
    border: none;
    border-radius: 4px;
    width: 400px;
    height: auto;
}


.users-search-box-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 46px;
}

.main-search-box-container{
    display: flex;
    flex-direction: row;
    position: relative;
}

.searching{
    margin-top: 5%;
    margin-left: -9%;
    outline: none;
    border: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}



.users-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px; 
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
    margin-right: 10vw;
}




.users-table{
    width: 85%;
    line-height: 4.5vh;
    border-collapse: collapse;
    font-size: 13px;
}

tr:hover{
    background-color: rgba(26, 168, 3, 0.15);
    /* cursor: pointer; */
}

tbody{
    /* cursor: pointer; */
    font-size: 13px;
}

td{ 
    color: rgba(0, 0, 0, 0.6);    
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 300;
}




.users-table-pic{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    /* width: 2.5vw;
    height: 5vh; */
    margin-top: 0.5vh;
    margin-left: 0.5vw;
    margin-bottom: 1.5vh;
}

.more-details{
    background-color: transparent;
    outline: none;
    border: none;
}

.drop-down2 {
    list-style: none;
    transition: .5s;
    margin-top: -5.5vh;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
}

.drop-down2 li {
    border-bottom: 1px solid #cccccc;
    text-align: center;
    background: #fbf6f6;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 5px;
    height: 33px;
    font-size: 14px;
}
.drop-down2 li:hover {
    color: #1aa803;
}

.user-App{
    position: absolute;
    width: 10rem;
    font-size: 11px;
    align-items: center;
    display: inline;
    margin-top: -1.5vh;
    margin-left: -3vw;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
    overflow-y: inherit;
}

.popup-click{
    position: relative;
}











@media only screen and (max-width: 900px){
    .users{
        width: 92%;
        margin-top: auto;
        margin-left: 4vw;
    }

    .search-box{
        width: auto;
    }
}
