.dashboard{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    height: 86vh;
}

.shadow-position {
    position: relative;
}

.shadow-position p {
    position: absolute;
    top: -20px;
    font-size: 12px;
    width: 250px;
    /* left: 10px; */
    left: 20px;
    /* background-color: white; */
    height: 13px;
    border-radius: 5px;
    display: none;
    z-index: 100000000;
}
.shadow-position:hover p {
    display: block !important;
}
.chizzy-position {
    position: relative;
}

.chizzy-position p {
    position: absolute;
    top: -20px;
    font-size: 12px;
    width: 250px;
    /* left: 10px; */
    left: 20px;
    /* background-color: white; */
    height: 13px;
    border-radius: 5px;
    display: none;
    z-index: 100000000;
}

.dashboard-props{
    overflow: auto;
    height: 86vh;
    overflow-x: hidden;
    /* background-color: white; */

}

.dashboard-grid{ 
    display: grid;
    grid-template-columns: repeat( 3, 1fr);
    text-decoration: none;
    width: 60%;
    height: auto;
    /* background-color: green; */
}

.infoItem1{
    width: 26vw;
    height: 21vh;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
    padding-bottom: 10%;
}

.infoItem2{
    width: 26vw;
    margin-left: 5%;
    background-color: white;
    text-align: left;
    padding-left: 10px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
}

.infoItem3{
    width: 26vw;
    margin-left: 10%;
    background-color: white;
    text-align: left;
    padding-left: 10px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 5%;
}

.infoItem4{
    width: 26vw;
    height: 21vh;
    background-color: white;
    text-align: left;
    padding-left: 8px;
    line-height: 30px;
    text-decoration: none;
    padding-bottom: 10%;
}

.infoItem5{
    width: 26vw;
    margin-left: 5%;
    background-color: white;
    text-align: left;
    padding-left: 10px;
    line-height: 30px;
    text-decoration: none;
}

.infoItem6{
    width: 26vw;
    margin-left: 10%;
    background-color: white;
    text-align: left;
    padding-left: 10px;
    line-height: 30px;
    text-decoration: none;
    padding-bottom: 5%;
}

.design1{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #1AA803;
    border-bottom-left-radius: 100px;
}

.design2{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #C4C4C4;
    border-bottom-left-radius: 100px;
}

.design3{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #F5EC16;
    border-bottom-left-radius: 100px;
}

.design4{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #F5EC16;
    border-bottom-left-radius: 100px;
}

.design5{
    width: 60px;
    height: 50px;
    float: right;
    background-color: #1AA803;
    border-bottom-left-radius: 100px;
}

.design6{
    width: 60px;
    height: 50px;
    float: right;
    padding-right: auto;
    background-color: #C4C4C4;
    border-bottom-left-radius: 100px;
}

.icons-div{
    text-align: center;
    color: white;
    padding-left: 23px;
    padding-top: 10px;
}

.icons-div2{
    margin-left: 25px;
    margin-top: 10px;
}

.itemTitle{    
    padding-left: 10px;
    padding-top: 3%;
    color: rgba(0, 0, 0, 0.9);
    font-size: 15px;
    font-weight: 400;
}

.itemTitle6{    
    padding-left: 10px;
    padding-top: 3%;
    color: rgba(0, 0, 0, 0.9); 
    font-size: 16px;
    font-weight: 400;
}

.iterations{
    padding-left: 20px;
    font-size: 30px;
    text-decoration: none;
    color: rgba(0, 0, 0, 1); 
    margin-top: 10px;
    font-weight: 300;
}


.noIterations{
    color: rgba(0, 0, 0, 1); 
    font-size: 30px;
    font-weight: 400;
}


.delivery-details{
    margin-top: 10px;
    width: 80.7vw;
    /* height: 80%; */
    background-color: white;
    padding-left: 15px;
    padding-top: 10px;
}

.delivery-properties{
    height: fit-content;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 10px;
}


.dashboard-table{
    width: 90%;
}


table{
    width: 94.7%;
    /* line-height: 4.5vh; */
    border-collapse: collapse;
    font-size: 13px;
}

tr:hover{
    background-color: rgba(26, 168, 3, 0.15);
}

tbody{
    font-size: 13px;
}


tr{ 
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 300;
}


td{ 
    color: rgba(0, 0, 0, 0.6);    
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 300;
    padding: 0.6rem 0;
}


th{
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #ddd;
}

th:hover{
    background-color: none;
}

.delivery-status{
    width: 15px;
    height: 15px;
    margin-top: 2px;
    border-radius: 50%;
    align-items: center;
    text-align: center;
}

.status{
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 20px;
}

.status1{
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 50%;
    /* padding: auto; */
    margin-top: 15px;
}


.more-details1{
    background-color: transparent;
    outline: none;
    border: none;
}

.drop-down5 {
    list-style: none;
    transition: .5s;
    margin-top: -5.5vh;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
    font-size: 14px;
}

.drop-down5 li {
    border-bottom: 1px solid #cccccc;
    text-align: left;
    background-color: #fbf6f6;
    padding-left: 1px;
    padding-right: 1px;
    height: 33px;
    padding-top: 4px
}

.drop-down5 li:hover {
    color: #1aa803;
}

.admin-App2 {
    position: absolute;
    width: 13rem;
    font-size: 10px;
    align-items: center;
    display: inline;
    margin-top: -2.4vh;
    margin-left: -3.3vw;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
    overflow-y: inherit;
}

.user-App1{
    position: absolute;
    width: 12rem;
    font-size: 13px;
    align-items: center;
    display: inline;
    margin-top: -2vh;
    margin-left: -0.9vw;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
    overflow-y: inherit;
}

.popup-click1{
    position: relative;
} 



@media only screen and (max-width: 900px){
    .dashboard{
        width: 98%;
        border-bottom: 20px;
        margin-top: auto;
        margin-left: 9vw;
        overflow: scroll;
    }

    .dashboard-grid{
        display: flex;
        flex-direction: column;
    }

    .itemTitle{
        font-size: 14px;
    }

    .itemTitle6{
        font-size: 14px;
    }

    .iterations{
        font-size: 15px;
    }

    .infoItem1{
        width: 90vw;
        margin-left: -10px;
    }

    .infoItem2{
        width: 90vw;
        margin-left: -10px;
        padding-bottom: 6px;
    }

    .infoItem3{
        width: 90vw;
        margin-left: -10px;
        padding-bottom: 6px;
    }

    .infoItem4{
        width: 90vw;
        margin-left: -10px;
        padding-bottom: 6px;
        margin-bottom: 10px;
    }

    .infoItem5{
        width: 90vw;
        margin-left: -10px;
        padding-bottom: 6px;
        margin-bottom: 10px
    }

    .infoItem6{
        width: 90vw;
        margin-left: -10px;
        padding-bottom: 6px;
        margin-bottom: 10px
    }

    .delivery-details{
        margin-left: -10px;
        overflow-x: scroll;
        margin-right: 20px;
        width: 90vw;
    }
    table{
        font-size: 9px;
        line-height: auto;
    }

    
    .status{
        width: 7px;
        height: 7px;
        background-color: green;
        border-radius: 20px;
    }
    
    .status1{
        width: 7px;
        height: 7px;
        background-color: red;
        border-radius: 20px;
    }
}
