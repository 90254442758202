.popup{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    /* overflow-y: scroll; */
    height: 86vh;
} 

.popup-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.popup-confirmation{
    padding-top: 10px;
    /* width: 32vw;
    height: 30vh; */
    padding: 4vh 7vw;
    margin-top: 19vh;
    background-color: #F5F5F5;;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid rgba(204, 202, 202, 1);
    box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
    align-items: center;
    text-align: center;
}

.popup-confirmation-info{
    margin-top: 1vh;
    margin-bottom: 5vh;
    font-weight: 400;
    color: rgba(26, 168, 3, 1);
    font-size: 17px;
}

.popup-rematched{
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 300;
    font-size: 17px;
}

.chizzys-popup-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #F5F5F5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 70px;
}

.chizzys-popup-buttons {
    border: 0;
    border-bottom-left-radius: 15px;
    height: 69px;
    width: 265px;
    text-align: center;
    background-color: #F5F5F5;
    font-size: 16px;
}

.chizzys-popup-buttons:hover{
    color: green;
}

.chizzys-popup-buttons1:hover{
    color: green;
}

.chizzys-popup-buttons1 {
    border: 0;
    border-left: 1px solid rgba(204, 202, 202, 1);
    border-bottom-right-radius: 15px;
    height: 69px;
    width: 265px;
    text-align: center;
    background-color: #F5F5F5;
    font-size: 16px;
}

.popup-dialog-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: white;
    /* padding-top: 8px; */
}

.popup-dialog-row button {
    all: unset
}

.popup-dialog{
    color: black;
    border-radius: 6px;
    padding: 15px 60px;
    cursor: pointer;
    font-size: medium;
    font-weight: 400;
    font-size: 17px;
}


.popup-dialog1{
    border-radius: 6px;
    color: black;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 60px;
    cursor: pointer;
    font-size: medium;
    font-weight: 400;
    font-size: 17px;
}

.popup-dialog:hover{
    color: green;
}

.popup-dialog1:hover{
    color: green;
}

.popup-hr{
    height: 9vh;
    margin-top: -0.1vh;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.1);
}

.chizzys-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chizzys-form button {
    background-color: rgba(26, 168, 3, 1);
    color: white;
    border: rgba(26, 168, 3, 1);
    border-radius: 5px;

    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    
   
    height: 30px;
    padding: 5px 12px;
}

.chizzys-form input {
    border-radius: 5px;
    width: 155px;
    height: 30px;
    padding: 10px 8px;
    margin: 10%px;
}

.chizzys-form input::placeholder{
    font-size: 14px;
}


/* button {
    all: unset
} */

.chizzys-parent-container {
    /* width: 81.7%; */
    margin-left: 19.4%;
    margin-right: 3%;
    margin-top: -88vh;
    background-color: white;
    /* overflow-y: scroll; */
    height: 86vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chizzys-arrow{    
    padding-top: 10px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    background-color: transparent;
    font-size: 30px;
    cursor: pointer;
    font-weight: lighter;
    align-self: start;
    justify-self: left;
}

.chizzy-confirmation-info{
    width: 35vw;
    height: 40vh;
    /* display: block; */
    background-color: #1faa08;
    border: 1px solid #1faa08;
    color:white;
    margin-bottom: 2px;
    text-align: center;
    font-size: 19px;
    font-weight: 300;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.chizzy-confirmation-info2 {
    width: 35vw;
    height: 35vh;
    /* display: block; */
    background-color: #1faa08;
    border: 1px solid #1faa08;
    color: white;
    margin-bottom: 11px;
    text-align: center;
    font-size: 19px;
    font-weight: 300;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

@media screen and (max-width: 900px){
    .popup{
        margin-top: auto;
    }
}