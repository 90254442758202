.fleet-managers{
    width: 81.7%;
    margin-left: 17.4%;
    margin-right: 15%;
    margin-top: -89vh;
    background-color: white;
    overflow-y: scroll;
    height: 86vh;
}

.fleet-properties{
    margin-left: 20px;
    margin-top: 20px;
}
.fleet-title{
    font-size: 26px; 
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 22px;
}

.all-fleet-table{
    width: 84%;
    line-height: 4.5vh;
    margin-left: 1.5vw;
    border-collapse: collapse;
    font-size: 13px;
    margin: 0 auto;
}

tr:hover{
    background-color: rgba(26, 168, 3, 0.15);
    /* cursor: pointer; */
}

tbody{
    /* cursor: pointer; */
    font-size: 13px;
}

td{ 
    color: rgba(0, 0, 0, 0.6);    
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-weight: 300;
}



.fleet-search-box-container{
    margin-left: -29vw;
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    margin-bottom: 2vh;
}

.fleet-manager-block{ 
    background-color: #1aa803;
    color: white;
    padding-left: 22px;
    padding-right: 20px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid #1aa803;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 3.5vh;
    margin-right: 12vw;
}

.drop-down4 {
    list-style: none;
    transition: .5s;
    margin-top: -5.5vh;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
    margin-left: 0.6vw;
}

.drop-down4 li {
    border-bottom: 1px solid #cccccc;
    text-align: center;
    background: #fbf6f6;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 5px;
    height: 39px;
    font-size: 14px;
    margin-left: -2vw;
}

.drop-down4 li:hover {
    color: #1aa803;
}

.fleet-App{
    position: absolute;
    width: 13rem;
    font-size: 10px;
    align-items: center;
    display: inline;
    margin-top: -1.5vh;
    margin-left: -5.3vw;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 3px;
    overflow-y: inherit;
}

.popup-click{
    position: relative;
}






@media only screen and (max-width: 900px){
    .fleet-managers{
        width: 94%;
        margin-top: auto;
        margin-left: 3.5vw;
    }

    .fleet-row{
        font-size: 9px;
    }

}
